
import { Vue, Component, Prop } from 'vue-property-decorator';
import { buildCommaSeparatedString } from '@/utils/utilsFunctions';
import { Getter, Mutation, State, Action } from 'vuex-class';
import { IPartnerWorkspace } from '@/types/partnerWorkspace.types';
import PlaceHolderImage from '@/ui/components/components/PlaceHolderImage.vue';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import { IAppState } from '@/store/modules/app/types';

/**
 * Component that represent all partner workspaces in Flex grid.
 */
@Component({
  components: {
    PlaceHolderImage,
    DeleteModalForm,
  },
})
export default class PartnerWorkspacesListFlexGrid extends Vue {
  @State('app') appState!: IAppState;
  @Prop({ default: () => [] }) partnerProjects!: any[];
  @Getter('partnerWorkspaces/workspaceFilter') workspaceFilter!: string;
  @Getter('partnerWorkspaces/getFilterByPartner') getFilterByPartner!: string;
  @Mutation('partnerWorkspaces/setWorkspaceId') setWorkspaceId!: (id: string) => void;
  @Mutation('partnerWorkspaces/setWorkspace') setWorkspace!: (workspace: IPartnerWorkspace | null) => void;
  @Action('partnerWorkspaces/deleteWorkspace') deleteWorkspace!: (id: string) => Promise<void>;

  get workspacesFilteredByPartner() {
    if (!this.getFilterByPartner || this.getFilterByPartner === '') {
      return this.partnerProjects;
    }
    return this.partnerProjects.filter((project: any) => {
      return project.partner_id === this.getFilterByPartner;
    });
  }

  get partnerWorkspacesFiltered() {
    if (this.workspaceFilter === '' || !this.workspaceFilter) {
      // if filter is empty or undefined return all projects
      return this.workspacesFilteredByPartner;
    } else {
      // if filter is not empty return filtered projects by name
      return this.workspacesFilteredByPartner.filter((project: any) => {
        return project.name.toLowerCase().includes(this.workspaceFilter.toLowerCase());
      });
    }
  }

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  removeProject(project: IPartnerWorkspace) {
    this.deleteWorkspace(project.id);
  }

  /**
   * Handles click on project card and redirects to project workspace
   * @param {string} id project id
   */
  handleOpen(id: string) {
    this.setWorkspaceId(id);
    this.$router.push(`/partner-workspace/${id}`);
  }

  /**
   * Returns formatted date in format dd.mm.yyyy
   */
  getFormattedDate(date: string) {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString();
  }

  /**
   * Defines current project city name
   * @return {string} city name
   */
  getProjectLocationName(project: any): string {
    const displayName = project.meta.location.display_name;
    if (displayName) {
      const partsOfName = displayName.split(',');
      return buildCommaSeparatedString(partsOfName, 4);
    }
    return '';
  }

  mounted() {
    this.setWorkspaceId('');
    this.setWorkspace(null);
  }
}
