
import { Vue, Component } from 'vue-property-decorator';
import PartnerWorkspacesListFlexGrid from '@/ui/components/lists/PartnerWorkspacesList/index.vue';
import MapBanner from '@/ui/components/components/MapBanner.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import { IPartner } from '@/types/partner.types';
import { Action, Getter, State } from 'vuex-class';
import { IAppState } from '@/store/modules/app/types';
import { IPartnerWorkspace } from '@/types/partnerWorkspace.types';

@Component({
  components: {
    PartnerWorkspacesListFlexGrid,
    MapBanner,
    CircleSpinner,
  },
})
export default class PartnerView extends Vue {
  @State('app') appState!: IAppState;
  @Action('partners/fetchPartners') fetchPartners!: () => IPartner[];
  @Getter('partnerWorkspaces/workspaces') workspaces!: IPartnerWorkspace[];
  @Action('partnerWorkspaces/fetchWorkspaces') fetchWorkspaces!: () => Promise<void>;

  showLoading = true;

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  async created() {
    await this.fetchWorkspaces();
    await this.fetchPartners();

    this.showLoading = false;
  }
}
